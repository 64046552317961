// src/components/NavMenu.js
import React from 'react';
import '../css/HelpContent.css'; // Import the CSS

const HelpContent = () => {
  return (
    <div className="help-content">
      <section class="benefit-section">
      <h2 class="center-text">Bienvenue sur Medcode</h2>
      </section>
    <section class="benefit-section">
      <h3 class="highlight-bg">💡 Simplifiez Votre Recherche</h3>
      <p>Naviguez à travers notre base de données étendue pour trouver rapidement les codes CCAM et CIM dont vous avez besoin.</p>
    </section>

    <section class="benefit-section">
      <h3 class="highlight-bg">🔍 Recherche Intuitive</h3>
      <p>Utilisez notre barre de recherche intelligente pour trouver les informations médicales pertinentes en un instant.</p>
    </section>

    <section class="benefit-section">
      <h3 class="highlight-bg">📋 Résultats Précis</h3>
      <p>Nos résultats de recherche vous fournissent des informations détaillées, y compris les tarifs et les descriptions associées.</p>
    </section>

    <section class="benefit-section">
      <h3 class="highlight-bg">🌟 Expérience Conviviale</h3>
      <p>Profitez d'une interface conviviale et moderne conçue pour vous aider à accéder rapidement aux informations dont vous avez besoin.
      <strong>La recherche est extrêmement rapide !</strong>
      </p>
    </section>

    <section class="benefit-section">
      <h3 class="highlight-bg">🗣️ Votre Voix Compte </h3>
      <p>Votre avis est essentiel pour nous. Le site est encore en constructions nous allons intégrer des fonctionnalités de commentaires pour que vous puissiez partager vos suggestions et contribuer à l'amélioration constante du site.</p>
    </section>

    </div>
  );
};

export default HelpContent;
