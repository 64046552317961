import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import '../css/NavMenu.css'; // Import the CSS

const NavMenu = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/'); // Redirect the user to the login page
  };

  return (
    <nav>
      <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/help">Prise en main</Link></li>
            <li><Link to="/treeview">CCAM chapites</Link></li>
      </ul>
    </nav>
  );
};

export default NavMenu;
