// src/components/HomePage.js
import React, { Suspense, lazy } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import the styles
import '../css/HomePage.css'; // Import the CSS

const SearchBar = lazy(() => import('./SearchBar'));
const CIMSearch = lazy(() => import('./CIMSearch'));
const Footer = lazy(() => import('./Footer'));


const HomePage = () => {
  return (
    <div className="homepage">
    <Suspense fallback={<div>Loading...</div>}>

        <Tabs>
        <TabList>
          <Tab>CCAM Search</Tab>
          <Tab>CIM Search</Tab>
        </TabList>
        <TabPanel>
          <SearchBar />
        </TabPanel>
        <TabPanel>
          <CIMSearch />
        </TabPanel>
      </Tabs>
      </Suspense>

        <Footer />
    </div>
  );
};

export default HomePage;