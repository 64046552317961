import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import NavMenu from './component/NavMenu';
import HomePage from './component/HomePage';
import HelpPage from './component/HelpPage';
import TreeViewer from './component/TreeViewer';

function App() {
  const token = localStorage.getItem('token');

  return (
    <div className="app">
      
    <Router>
      <div>
        <NavMenu />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/treeview" element={<TreeViewer />} />
        </Routes>
      </div>
    </Router>
  </div>

  );
}

export default App; 
