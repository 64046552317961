// HelpPage.js
import React from 'react';
import Footer from './Footer';
import HelpContent from './HelpContent';


const HelpPage = () => {
  return (
    <div className="help-page">
      <HelpContent />
      <Footer />
    </div>
  );
};

export default HelpPage;
